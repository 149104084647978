<template>
  <div id="tool-crawl-product">
    <KTCodePreview v-bind:title="title">
      <template v-slot:toolbar>
        <div class="row">
          <b-dropdown size="sm" right>
            <template slot="button-content">
              <i style="font-size: 1rem" class="fas fa-cog"></i>
              <span class="font-weight-bolder">Thao tác</span>
            </template>
            <b-dropdown-item @click="conductCrawl">
              <span>
                <i style="font-size: 1rem" class="far fa-file-excel"></i>
                &nbsp; Tiến hành crawl</span
              >
            </b-dropdown-item>
            <b-dropdown-item @click="openModal('create')">
              <span>
                <i style="font-size: 1rem" class="far fa-file-excel"></i>
                &nbsp; Thêm sản phẩm</span
              >
            </b-dropdown-item>
            <b-dropdown-item>
              <span @click="exportExcel">
                <i style="font-size: 1rem" class="far fa-file-excel"></i>
                &nbsp; Export Excel</span
              >
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template v-slot:preview>
        <div class="container-fuild">
          <b-row class="mb-5 align-items-end w-100 justify-content-between m-0">
            <b-col cols="12" md="4" class="p-0">
              <b-form-group
                id="input-group-1"
                label="Nhập tên sản phẩm:"
                label-for="input-1"
                label-class="font-weight-bold"
                class="mb-0"
              >
                <b-form-input
                  size="sm"
                  v-model="paramsFilter.name"
                  placeholder="Nhập tên sản phẩm"
                  append-icon="search"
                  single-line
                  hide-details
                  class="input-style"
                  @input="searchName"
                  v-on:keyup.enter="onFilter()"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3" class="p-0">
              <b-form-group
                id="input-group-1"
                label="Chọn danh mục:"
                label-for="input-1"
                label-class="font-weight-bold"
                class=" p-0 mb-0"
              >
                <b-form-select
                  size="sm"
                  v-model="paramsFilter.category"
                  :options="optionCategory"
                  value-field="value"
                  text-field="text"
                  class="select-style"
                ></b-form-select>
              </b-form-group>
              <!-- </div> -->
            </b-col>
            <b-col cols="12" md="2" class="p-0">
              <b-form-group
                id="input-group-1"
                label="Hiển thị:"
                label-for="input-1"
                label-class="font-weight-bold"
                class="p-0 mb-0"
              >
                <b-form-select
                  size="sm"
                  v-model="paramsFilter.limit"
                  :options="listLimit"
                  value-field="value"
                  text-field="value"
                  class="col-md-12 select-style"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="1" md="auto" class="p-0 text-right">
              <label class="input-group-1__BV_label_ mb-1"></label>
              <b-button
                size="sm"
                variant="primary"
                style="fontweight: 600; width: 70px"
                @click="onFilter()"
                >Lọc</b-button
              >
            </b-col>
            <b-col cols="1" md="auto" class="p-0 text-right">
              <label class="input-group-1__BV_label_ mb-1"></label>
              <b-button
                size="sm"
                style="fontweight: 600; width: 70px"
                @click="resetFilter()"
                >Làm mới</b-button
              >
            </b-col>
          </b-row>
        </div>

        <div id="scroll-table">
          <div>
            <b-table
              :items="listData"
              :fields="customField"
              :small="true"
              :busy="onLoading"
              responsive
              bordered
              hover
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="20"></vcl-table>
              </template>
              <template v-slot:cell(name)="row">
                <b
                  v-text="row.item.name"
                  class="d-block"
                  style="white-space: normal"
                ></b>
              </template>
              <template v-slot:cell(category)="row">
                <div style="text-align: center">
                  <span v-text="row.item.category"></span>
                </div>
              </template>
              <template #cell()="data">
                <span
                  @click="openModal('update', data.item)"
                  style="display: block"
                  :class="{
                    'text-success font-weight-bold':
                      comparePrice(data.item)['keyWithMinPrice'] ==
                      data['field']['key'],
                    'text-danger font-weight-bold':
                      comparePrice(data.item)['keyWithMaxPrice'] ==
                      data['field']['key'],
                  }"
                  >{{ data.value }}</span
                >
              </template>
              <template v-slot:cell(created_at)="row">
                <span style="display: block">{{
                  formatDate(row.item.created_at)
                }}</span>
              </template>
            </b-table>
          </div>
        </div>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số :
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-if="numberOfPage >= 2"
              class="custom-pagination"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1"
              last-class="page-item-last btn btn-icon btn-sm m-1"
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <ModelAddUpdateProduct
          @getProductCrawl="getProductCrawl"
          :show-modal="modalOpen"
          :type-modal="typeModal"
          :selectedItemOpenModel="selectedItemOpenModel"
          @close="modalClose"
        ></ModelAddUpdateProduct>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import {
  showConfirmDialog,
  showLoadingDialog,
  showSuccessNotification,
  closeSwal,
} from '@/utils/sweet-alert2';
import { mapGetters } from 'vuex';
import { VclTable } from 'vue-content-loading';
import moment from 'moment';
import ModelAddUpdateProduct from '@/view/pages/crawl-products/modelAddUpdateProduct.vue';

import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { makeToastFaile } from '@/utils/common';

import { TIME_OUT } from '@/utils/constants';
import { PARTNER_API_STATUS } from '@/utils/enum';

import KTCodePreview from '@/view/content/CodePreview.vue';
import { makeToastSuccess } from '../../../utils/common';
import fileDownload from '@/utils/file-download';
import axios from 'axios';
import { TIME_TRIGGER } from '../../../utils/constants';
import debounce from 'debounce';

export default {
  components: {
    KTCodePreview,
    VclTable,
    ModelAddUpdateProduct,
  },
  data() {
    return {
      typeModal: '',
      title: '',
      modalOpen: false,
      paramsFilter: {
        name: null,
        category: null,
        limit: 10,
      },
      showEditNoteModal: true,
      listData: [],
      shopCode: [],
      selectedItemOpenModel: {},
      listLimit: [{ value: '10' }, { value: '20' }, { value: '50' }],
      onLoading: false,
      numberOfPage: 1,
      totalItems: 0,
      listCategory: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'name',
          label: 'Tên sản phẩm',
          thStyle: {
            color: 'rgb(24, 28, 50)',
            textAlign: 'center',
            width: '300px',
          },
        },
        {
          key: 'category',
          label: 'Danh mục sản phẩm',
          thStyle: {
            color: 'rgb(24, 28, 50)',
            textAlign: 'center',
            width: '100px',
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['breadcrumbs']),
    hasPagination() {
      return this.numberOfPage >= 2;
    },
    optionCategory() {
      return this.listCategory.map((item) => {
        return item.category === 'Chọn danh mục'
          ? { value: null, text: 'Chọn danh mục' }
          : {
              value: item.category,
              text:
                item.category.charAt(0).toUpperCase() +
                item.category.substring(1),
            };
      });
    },
    customField() {
      let data = [...this.fields];
      let dataDefault = [
        {
          key: 'created_at',
          label: 'Ngày crawl',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '100px',
          },
        },
      ];
      for (const item of this.shopCode) {
        data.push({
          key: item.code,
          label: item.name,
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '100px',
          },
        });
      }
      data.push(dataDefault[0]);
      return data;
    },
  },
  mounted() {
    this.title = 'Danh sách sản phẩm thu thập';
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  created() {
    this.getProductCrawl();
    this.getCategory();
    this.getStores();
  },
  methods: {
    debounceSearch: debounce(function() {
      this.getProductCrawl();
    }, TIME_TRIGGER),
    searchName() {
      this.debounceSearch();
    },
    openModal(type, item) {
      this.selectedItemOpenModel = item ?? '';
      this.modalOpen = true;
      this.typeModal = type;
    },
    modalClose(value) {
      this.selectedItemOpenModel = {};
      this.modalOpen = value;
    },
    async getProductCrawl() {
      this.page = this.$route.query.page || 1;
      const params = this.getFilterParams();
      ApiService.query(
        '/tool/crawl-product',
        { params },
        { timeout: TIME_OUT },
      ).then(({ data }) => {
        this.listData = data.data.data;
        this.totalItems = data.data.pagination.total;
        this.numberOfPage = data.data.pagination.lastPage;
      });
    },

    comparePrice(item) {
      let maxPrice = item.di_dong_viet;
      let minPrice = item.di_dong_viet;
      let keyWithMaxPrice = 'di_dong_viet';
      let keyWithMinPrice = 'di_dong_viet';

      if (item.di_dong_viet) {
        if (
          parseFloat(item.di_dong_viet.replace(/\./g, '')) >
          parseFloat(maxPrice.replace(/\./g, ''))
        ) {
          maxPrice = item.di_dong_viet;
          keyWithMaxPrice = 'di_dong_viet';
        }
        if (
          parseFloat(item.di_dong_viet.replace(/\./g, '')) <
          parseFloat(minPrice.replace(/\./g, ''))
        ) {
          minPrice = item.di_dong_viet;
          keyWithMinPrice = 'di_dong_viet';
        }
      }

      if (item.the_gioi_di_dong) {
        if (
          parseFloat(item.the_gioi_di_dong.replace(/\./g, '')) >
          parseFloat(maxPrice.replace(/\./g, ''))
        ) {
          maxPrice = item.the_gioi_di_dong;
          keyWithMaxPrice = 'the_gioi_di_dong';
        }
        if (
          parseFloat(item.the_gioi_di_dong.replace(/\./g, '')) <
          parseFloat(minPrice.replace(/\./g, ''))
        ) {
          minPrice = item.the_gioi_di_dong;
          keyWithMinPrice = 'the_gioi_di_dong';
        }
      }

      if (item.fpt) {
        if (
          parseFloat(item.fpt.replace(/\./g, '')) >
          parseFloat(maxPrice.replace(/\./g, ''))
        ) {
          maxPrice = item.fpt;
          keyWithMaxPrice = 'fpt';
        }
        if (
          parseFloat(item.fpt.replace(/\./g, '')) <
          parseFloat(minPrice.replace(/\./g, ''))
        ) {
          minPrice = item.fpt;
          keyWithMinPrice = 'fpt';
        }
      }

      if (item.cellphones) {
        if (
          parseFloat(item.cellphones.replace(/\./g, '')) >
          parseFloat(maxPrice.replace(/\./g, ''))
        ) {
          maxPrice = item.cellphones;
          keyWithMaxPrice = 'cellphones';
        }
        if (
          parseFloat(item.cellphones.replace(/\./g, '')) <
          parseFloat(minPrice.replace(/\./g, ''))
        ) {
          minPrice = item.cellphones;
          keyWithMinPrice = 'cellphones';
        }
      }
      return {
        maxPrice,
        minPrice,
        keyWithMaxPrice,
        keyWithMinPrice,
      };
    },
    async getStores() {
      try {
        const response = await ApiService.get('/tool/shop-code');
        this.shopCode = response.data.data;
      } catch (error) {
        makeToastFaile('Lỗi! Không tìm thấy cửa hàng');
      }
    },
    async getCategory() {
      ApiService.query('/tool/category', { timeout: TIME_OUT }).then(
        ({ data }) => {
          this.listCategory = [{ category: 'Chọn danh mục' }, ...data.data];
        },
      );
    },
    async exportExcel() {
      await axios({
        url: '/tool/export-excel',
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: function(progressEvent) {
          console.log(progressEvent);
        },
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
        makeToastSuccess('Xuất excel thành công');
      });
    },

    async apiConductCrawl() {
      ApiService.query('/tool/conduct-crawl', { timeout: 1000000 })
        .then(({ data }) => {
          if (data.status) {
            showSuccessNotification('Thành công', 'Dữ liệu đã được cập nhật.');
            this.getCategory();
            this.getStores();
            this.getProductCrawl();
          }
        })
        .catch((error) => {
          closeSwal();
          makeToastFaile(
            error.response.data ? error.response.data.message : 'Lỗi',
          );
        });
    },
    async conductCrawl() {
      try {
        showConfirmDialog(
          'Tiến hành crawl sản phẩm!',
          'Bạn có chắc muốn crawl lại đanh sách sản phẩm không ?',
        )
          .then(() => {
            showLoadingDialog(
              'Đang chờ...',
              'Vui lòng đợi trong khi quá trình chạy...',
            );
            return this.apiConductCrawl();
          })
          .catch(() => {});
      } catch (error) {
        closeSwal();
        makeToastFaile(
          error.response.data ? error.response.data.message : 'Lỗi',
        );
      }
    },

    async fetchData() {
      this.getProductCrawl();
    },
    onFilter() {
      // this.$router.push();
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-product',
      });
      this.getProductCrawl();
    },
    resetFilter() {
      this.paramsFilter.name = null;
      this.paramsFilter.category = null;
      this.paramsFilter.limit = 10;
      this.name = '';
      this.category = this.getProductCrawl();
    },
    statusClasses(status) {
      return status === PARTNER_API_STATUS.ACTIVE
        ? 'label-light-success'
        : 'label-light-danger';
    },
    editPartnerApiById(partnerApiId) {
      this.$router.push({
        name: 'edit-web-partner',
        params: { partnerApiId },
      });
    },
    getFilterParams() {
      return {
        page: this.$route.query.page || 1,
        limit: this.paramsFilter.limit,
        name: this.paramsFilter.name ?? null,
        category: this.paramsFilter.category ?? null,
      };
    },
    formatDate: function(dateVal) {
      const date = moment(dateVal);
      return date.format('DD/MM/yyyy');
    },
  },
};
</script>

<style lang="scss" scoped>
.page-item.disabled {
  cursor: not-allowed;
  &.bv-d-xs-down-none {
    .page-link {
      margin: 0.35rem 0.25rem;
      text-align: center;
    }
  }
}
#tool-crawl-product {
  display: grid;
  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }

  #scroll-table ::v-deep() {
    table {
      margin: 0;
      border-collapse: collapse;
      border-spacing: 0;
      table-layout: fixed;
      th {
        position: relative;
        z-index: 1;
      }

      td,
      th {
        vertical-align: middle;
        padding: 0.75rem;
      }

      th:first-child {
        position: sticky !important;
        left: 0;
        z-index: 2;
        background-color: white;
      }
      th:nth-child(2) {
        position: sticky !important;
        left: 250px;
        z-index: 2;
        background-color: white;
      }

      td:first-child {
        position: sticky;
        left: 0;
        z-index: 0;
        background-color: white;
      }

      td:nth-child(2) {
        position: sticky;
        left: 250px;
        z-index: 0;
        background-color: white;
      }

      .font-size-setting {
        font-size: 0.86rem;
      }
    }
  }
}
</style>
